import Swiper from 'swiper';
import { Navigation, Pagination, A11y } from 'swiper/modules/index.mjs';
import { debounce } from 'lodash';
import { removeHiddenAssets } from './util';

const SWIPERID = '.js-testimonials-slider';

const TestimonialTiles = (() => {
    let mySwiper;

    function handleSwiperTestimonials() {
        if (!$(SWIPERID).length > 0) {
            return;
        }

        removeHiddenAssets($(SWIPERID), '.swiper-slide');
        const destroyParams = {
            cleanStyles: true,
        };

        if (typeof mySwiper !== 'undefined') {
            mySwiper.destroy(destroyParams);
        }

        mySwiper = undefined;

        const options = {
            // configure Swiper to use modules
            modules: [Navigation, Pagination, A11y],
            spaceBetween: 24,
            slidesPerView: 1,
            centeredSlides: true,
            breakpointsInverse: true,
            pagination: {
                el: '.js-testimonial-slider-pagination',
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: '.js-testimonial-slider-next',
                prevEl: '.js-testimonial-slider-prev',
            },
            a11y: {
                prevSlideMessage: `${window.resources['slider.prev.slide.label']}`,
                nextSlideMessage: `${window.resources['slider.next.slide.label']}`,
                firstSlideMessage: `${window.resources['slider.first.slide.label']}`,
                lastSlideMessage: `${window.resources['slider.last.slide.label']}`,
            },
            breakpoints: {
                768: {
                    slidesPerView: 1.5,
                    spaceBetween: 90,
                },
                901: {
                    slidesPerView: 1,
                },
            },
        };
        if (typeof mySwiper === 'undefined') {
            mySwiper = new Swiper(SWIPERID, options);
        }
    }

    return {
        init() {
            handleSwiperTestimonials();
        },
        resize() {
            handleSwiperTestimonials();
        },
    };
})();

let windowWidth = $(window).width();

export default (() => {
    $(TestimonialTiles.init);

    $(window).on('resize', debounce(() => {
        if ($(window).width() !== windowWidth) {
            windowWidth = $(window).width();
            TestimonialTiles.resize();
        }
    }, 300));
})();
